/* eslint-disable no-restricted-globals */
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import "./indexPage.css"

import {
  featuredApp,
  indexTabList,
  topicList,
} from "../../misc"

import {
  SEO,
  TabBar,
} from "../../components"

import { getAlgoliaClient } from "../../search/getAlgoliaClient"

import {
  InnerContainer,
  IntermediateContainer,
  OuterContainer,
} from "../../containerComponents"

import { AppListing } from "./AppListing"
import { Featured } from "./Featured"
import { Filtering } from "./Filtering"

import { PageParagraph } from "./PageParagraph"


const searchClient = getAlgoliaClient()
const index = searchClient.initIndex("dev_DAM")


class indexPage extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      allDpApp: PropTypes.any,
    }).isRequired,
    pageContext: PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      paragraphTitle: PropTypes.string.isRequired,
      paragraphText: PropTypes.string.isRequired,
    }).isRequired,
  }

  state = {
    hitList: [],
    search: {
      textSearch: "",
      checkedObj: {},
    },
  }

  searchRequestTime = 0

  searchClient = getAlgoliaClient()

  constructor(props) {
    super(props)

    // console.log //
    const { data, pageContext } = props
    console.log({ page: this, pageContext, data })

    // this.tabName //
    const { tabName } = pageContext
    this.tabName = tabName

    // this.state... //
    this.state.search.checkedObj[tabName] = true

    // this.filteredAppIdList //
    const { allDpApp: { nodes: dpAppList } } = data
    this.filteredAppIdList = dpAppList
      .filter(({ dpapp: { topics } }) => topics.indexOf(this.tabName) !== -1)
      .map(({ dpapp: { id } }) => id)

    // this.dpappPerId this.dpappPerName//
    this.dpappPerId = {}
    this.dpappPerName = {}
    dpAppList.forEach((dpappNode) => {
      this.dpappPerId[dpappNode.dpapp.id] = dpappNode
      this.dpappPerName[dpappNode.dpapp.name] = dpappNode
    })
    if (Object.keys(this.dpappPerId).lenght !== dpAppList.lenght) {
      throw new Error("dpappPerId has too few keys. Overlapping ids?")
    }

    // this.featured //
    this.featuredAppList = (featuredApp[this.tabName] || []).map((name) => {
      return this.dpapp2appInfo(this.dpappPerName[name])
    })
  }

  componentDidMount() {
    this.getSearchTextFromHash({
      location,
      setHash: (hash) => { location.hash = hash },
    }).then(() => {
      this.query(this.state.search)
    })
  }

  async getSearchTextFromHash({ location, setHash }) {
    const m = location.hash.match(/[?&]search=(?<text>[^&]*)/)
    if (m) {
      // Remove the search from the fragment query
      setHash(
        location.hash
          .replace(/search=[^&]*(&|$)/g, "")
          .replace(/\?$/, "")
      )

      const { search } = this.state
      if (!search.textSearch) {
        const textSearch = decodeURIComponent(m.groups.text)
        await new Promise((resolve) => this.setState({
          search: { ...search, textSearch },
        }, resolve))
      }
    }
  }

  getListing = () => {
    const list = this.state.search.textSearch || this.state.hitList.length ?
      this.state.hitList :
      this.filteredAppIdList

    const listing = list
      .map(this.getDpapp)
      .map(this.dpapp2appInfo)
    return listing
  }

  getDpapp = (id) => {
    if (!id) throw new Error("Missing argument id")
    const dpapp = this.dpappPerId[id]
    if (!dpapp) throw new Error(`getDpapp didn't find dpapp with id '${id}'`)
    return dpapp
  }

  dpapp2appInfo = ({
    dpapp: {
      id,
      name,
      url,
      topics,
      title,
      packageInfo: { description },
    },
    childrenFile = [],
  }) => {
    const fileList = childrenFile.filter(({ name: n }) => n === "defaultIcon")
    return {
      id,
      name,
      title,
      description,
      url,
      topics,
      defaultIconFileList: fileList,
      active: false,
      installed: false,
    }
  }

  async query({ textSearch, checkedObj }) {
    const tags = Object.keys(checkedObj).filter((tag) => checkedObj[tag])
    const time = Date.now()
    const result = await index.search(textSearch, {
      hitsPerPage: 10,
      page: 0,
      analytics: false,
      attributesToRetrieve: "id",
      attributesToSnippet: "*:20",
      getRankingInfo: true,
      facets: "*,",
      tagFilters: tags,
    })
    if (time >= this.searchRequestTime) {
      const { hits } = result
      if (hits) {
        console.log({ hits })
        const hitList = hits.map(({ id }) => {
          if (id in this.dpappPerId) {
            return id
          }
          console.error(id, "-- This dpapp id obtained from Algolia was",
            "filtered out because it couldn't be found in this.dpappPerId[].",
            "You may need to run `gatsby build` to update the Algolia index")
          return null
        }).filter((id) => id)

        this.setState({ hitList }, () => { this.searchRequestTime = time })
      } else {
        console.warn("search returned no hitList:", result)
      }
    }
  }

  render() {
    const {
      tabName,
      paragraphTitle,
      paragraphText,
    } = this.props.pageContext

    const noIconTriplet = this.tabName !== "fusion"

    return <OuterContainer className="page-apps">
      <SEO
        title="Apps & Integrations"
        keywords={["gatsby", "application", "react"]}
      />
      <IntermediateContainer>
        <TabBar
          basePath=""
          activeTab={tabName}
          tabList={indexTabList}
          style={{
            justifyContent: "flex-start",
          }}
        />
        <InnerContainer>
          <PageParagraph
            title={paragraphTitle}
            text={paragraphText}
          />
          <Featured
            apps={this.featuredAppList}
            noIconTriplet={noIconTriplet}
          />
          <Filtering
            invisible={this.tabName !== "fusion"}
            topicList={topicList}
            checkedObj={this.state.search.checkedObj}
            onChange={({ checkedObj }) => {
              if (!checkedObj) throw new Error(checkedObj)
              // eslint-disable-next-line react/no-access-state-in-setstate
              const search = { ...this.state.search, checkedObj }
              this.setState({ search })
              this.query(search)
            }}
          />
          <AppListing
            apps={this.getListing()}
            textSearch={this.state.search.textSearch}
            noIconTriplet={noIconTriplet}
            onChange={({ textSearch }) => {
              // eslint-disable-next-line react/no-access-state-in-setstate
              const search = { ...this.state.search, textSearch }
              this.setState({ search })
              this.query(search)
            }}
          />
        </InnerContainer>
      </IntermediateContainer>
    </OuterContainer>
  }
}

export default indexPage

export const query = graphql`
  query {
    allDpApp {
      nodes {
        dpapp {
          id
          name
          url
          title
          topics
          packageInfo {
            description
          }
        }
        childrenFile {
          name
          publicURL
          internal: childLoadNodeContent {
            content
          }
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              src
            }
            original {
              width
              height
            }
          }
        }
      }
    }
  }
`
