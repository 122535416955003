import React from "react"
import PropTypes from "prop-types"

class Filtering extends React.Component {
  static propTypes = {
    checkedObj: PropTypes.objectOf(PropTypes.bool).isRequired,
    invisible: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    topicList: PropTypes.arrayOf(PropTypes.shape({
      groupTitle: PropTypes.string.isRequired,
      groupList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
      })).isRequired,
    })).isRequired,
  }

  handleChange = (ev) => {
    const { onChange, checkedObj } = this.props
    if (ev.target.type === "checkbox") {
      checkedObj[ev.target.name] = !checkedObj[ev.target.name]
    } else {
      console.warn(
        "[AppSearchForm] Unhandled form target type:",
        ev.target.type
      )
    }
    onChange({ checkedObj })
  }

  renderTopic = ({ name, title }) => {
    const { checkedObj } = this.props
    return <li key={name} className="dp-CustomCheckbox">
      <input
        type="checkbox"
        id={`checkbox-${name}`}
        name={name}
        defaultChecked={checkedObj[name] || false}
      />
      <label htmlFor={`checkbox-${name}`}>
        <svg className="svg-ico"><use xlinkHref={`#icon-${name}`} /></svg>
        {title}
      </label>
    </li>
  }

  renderGroup = ({ groupTitle, groupList }) => {
    return <React.Fragment key={groupTitle}>
      <h4 className="filter-title">{groupTitle}</h4>
      <ul className="filter-list">
        {groupList.map(this.renderTopic)}
      </ul>
    </React.Fragment>
  }

  render() {
    const { invisible, topicList } = this.props
    return <div className="cell large-2" onChange={this.handleChange}>
      {
        invisible ? null :
        <div className="apps-filters">
          {topicList.map(this.renderGroup)}
        </div>
      }
    </div>
  }
}

export { Filtering }
