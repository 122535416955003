import React from "react"
import PropTypes from "prop-types"

import {
  TopAppEntry,
} from "../../components"

class Featured extends React.Component {
  static propTypes = {
    apps: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      installed: PropTypes.bool.isRequired,
    })).isRequired,
    noIconTriplet: PropTypes.bool.isRequired,
  }

  render() {
    const { apps } = this.props
    return <div className="cell large-8">
      <div className="grid-x grid-margin-x featured-apps fusion-title-apps">
        <div className="cell large-10 m-0">
          <h4 className="title-apps">Featured Apps</h4>
        </div>
        {apps.map((appInfo) => (
          <TopAppEntry
            {...appInfo}
            key={appInfo.name}
            noIconTriplet={this.props.noIconTriplet}
          />))}
      </div>
    </div>
  }
}

export { Featured }
