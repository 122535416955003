import React from "react"
import PropTypes from "prop-types"

import { AppEntry } from "../../components"


class AppListing extends React.Component {
  static propTypes = {
    apps: PropTypes.arrayOf(PropTypes.object).isRequired,
    noIconTriplet: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    textSearch: PropTypes.string.isRequired,
  }

  handleChange = (ev) => {
    let { onChange, textSearch } = this.props
    if (ev.target.type === "search") {
      textSearch = ev.target.value
    } else {
      console.warn(
        "[AppSearchForm] Unhandled form target type:",
        ev.target.type
      )
    }
    onChange({ textSearch })
  }

  renderEntry = (appInfo) => {
    return <AppEntry
      {...appInfo}
      key={appInfo.name}
      noIconTriplet={this.props.noIconTriplet}
    />
  }

  render() {
    const { apps, textSearch } = this.props
    return <div className="cell large-8">
      <form className="apps-search" onChange={this.handleChange}>
        <input type="search" placeholder="Search" defaultValue={textSearch} />
        <svg className="svg-ico"><use xlinkHref="#icon-find" /></svg>
      </form>
      <div className="featured-apps-list">
        {apps.map(this.renderEntry)}
      </div>
    </div>
  }
}

export { AppListing }
