import React from "react"
import PropTypes from "prop-types"

class PageParagraph extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }

  render() {
    const { title, text } = this.props

    return <div className="cell large-2">
      <div className="details-panel">
        <h4 className="title-apps">{title}</h4>
        <p className="paragraph_small">
          {text}
        </p>
      </div>
    </div>
  }
}

export { PageParagraph }
